<template>
  <div>
    <div class="hidden landscape:block pt-8 px-4 bg-mbg text-sm font-semibold text-mtn">
      <p class="hidden landscape:group-data-[menu=true]/layout:block landscape:xl:block pb-2 font-normal text-xs">
        Navigation
      </p>
      <UVerticalNavigation
        :links="menuItems"
        :ui="{ base: 'gap-3 py-2.5', padding: 'px-0', active : 'text-mta before:bg-mbg', inactive: 'text-mtn hover:text-mth hover:before:bg-mbg', icon: { active: 'w-6 h-6 text-mia', inactive: 'w-6 h-6 text-min group-hover:text-mih'}, label: 'hidden landscape:group-data-[menu=true]/layout:block landscape:xl:block whitespace-nowrap font-semibold' }"
        @click="$emit('click')"
      />
    </div>
    <div class="landscape:hidden p-4">
      <USelect
        v-model="currentItem"
        :options="menuItems"
        value-attribute="to"
        variant="none"
        select-class="bg-white"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useUser } from '~/stores/user'

const emits = defineEmits<{
  click: []
}>()
const user = useUser()
const route = useRoute()

const items = computed(() => {
  return [
    { label: 'Dashboards', icon: 'i-heroicons-presentation-chart-line', to: '/dashboards' },
    { label: 'Valuations', icon: 'i-heroicons-currency-dollar', to: '/valuations' },
    { label: 'Performance', icon: 'i-heroicons-rocket-launch', to: '/performance' },
    { label: 'Simulations', icon: 'i-heroicons-calculator', to: '/simulations', hidden: !user.canEdit },
    { label: 'Cap Tables', icon: 'i-heroicons-table-cells', to: '/tables' },
    { label: 'Plans', icon: 'i-heroicons-square-3-stack-3d', to: '/plans' },
    { label: 'Documents', icon: 'i-heroicons-archive-box', to: '/documents' },
    { label: 'Settings', icon: 'i-heroicons-pencil-square', to: '/settings', hidden: !user.canAdmin }
  ]
})

const menuItems = computed(() => {
  return items.value.filter(({ hidden }) => !hidden)
})

const currentItem = computed({
  get() {
    return route.path
  },
  set(val) {
    navigateTo(val)
    emits('click')
  }
})

</script>
